<template>
  <div class="wrapper">
    <div class="page">
      <div id="loader"></div>
      <div id="main-loading-cover" :class="{'disabled_content':menuLoading}">
        <div class="page-inner" id="main_page">
          <div class="va-card mb-4">
            <div class="dash_card_body pad-lr-0 ov-h">
              <div class="header_section d-block padd-30 pt-0 pb-0">
                <div class="w-100 t-wrap">
                  <h4 class="menu_header">Menu</h4>
                </div>
                <div class="back-btn">
                  <router-link :to="{ name: 'menu' }"><i class="fa fa-arrow-left" aria-hidden="true"></i>
                  </router-link>
                  <span>{{ title }}</span>
                </div>
              </div>

              <div class="create_items_section">
                <form @submit.prevent="updateMenu">
                  <div class="row">
                    <div class="col-md-6 bd-right pad-lr-0">
                      <div class="pl-5 pr-4 bd-b">
                        <div class="form-group">
                          <label>Title</label>
                          <div>
                            <input class="form-control" v-model="title" @blur="v$.title.$touch">
                          </div>
                          <div class="input_error" v-if="v$.title.$error">This field is required.</div>
                        </div>
                      </div><!-- END Title and Desc-->
                      <div class="pl-5 pr-4 bd-b">
                        <div class="form-group">
                          <h2 class="section-title">Menu Availability</h2>
                          <span>Menu is active. Customers can view and order from this menu</span>
                        </div>
                        
                      </div>
                      <div class="pl-5 pr-4 pt-4 mb-4">

                        <div class="row" id="period_div" v-for="(period, pindx) in periodTimeCount" :key="period.key">
                         
                          <div class="col-1 order-last">
                            <span v-if="period.key !== 1" @click="removePeriodCountDivs(period.key, day[pindx])"
                              class="div_remove_btn remove_icon glyphicon glyphicon-remove"></span>
                          </div>
                          <div class="col-md-11">
                            <div class="row">
                              <div class="col-md-4">
                                <div class="form-group">
                                  <label>Day</label>
                                  <select class="form-control" v-model="day[pindx]" @blur="v$.day.$touch">
                                    <option v-for="day in dayOfWeek" :key="day.key" :value="day.key">{{ day.value }}
                                    </option>
                                  </select>
                                </div>
                                <div class="input_error" v-if="v$.day.$error">This field is required.</div>
                              </div>
                              <div class="col-md-4">
                                <div class="form-group">
                                  <label>Start Time</label>
                                  <select class="form-control" v-model="startTime[pindx]" @blur="v$.startTime.$touch">
                                    <option v-for="time in startTimes" :key="time.key" :value="time.value">{{ time.key
                                    }}
                                    </option>
                                  </select>
                                </div>
                                <div class="input_error" v-if="v$.startTime.$error">This field is required.</div>
                              </div>
                              <div class="col-md-4">
                                <div class="form-group">
                                  <label>End Time</label>
                                  <select class="form-control" v-model="endTime[pindx]" @blur="v$.endTime.$touch"
                                    name="end_time[]">
                                    <option v-for="(etime, index) in endTimes" :key="index" :value="etime.value">
                                      {{ etime.key }}</option>
                                  </select>
                                </div>
                                <div class="input_error" v-if="v$.endTime.$error">This field is required.</div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="add_days_btn">
                          <label @click="createPeriodCountDivs" style="cursor:pointer;"><span
                            class="glyphicon glyphicon-plus"></span> Add More Days Times</label>
                        </div>
                      </div>
                      <!--Manage availabilty-->
                    </div>
                    <!--Right Col-->
                    <div class="col-md-6 pad-l-0">
                      <div class="pl-4 pr-5 bd-b">
                        <h2 class="section-title">Branches To include</h2>
                        <div class="form-group">
                          <label>Branches To include</label>
                          <div>
                            <Multiselect v-model="stores" mode="tags" placeholder="Select Branches To include"
                              label="name" 
                              :close-on-select="true" 
                              :searchable="true"
                              :object="true"
                              :resolve-on-load="false"
                              :delay="0"
                              :min-chars="1"
                              :options="options">
                              <template v-slot:tag="{ option, handleTagRemove, disabled }">
                                <div class="multiselect-tag is-user">
                                  {{ option.name }}
                                  <span v-if="!disabled" class="multiselect-tag-remove"
                                    @mousedown.prevent="handleTagRemove(option, $event)">
                                    <span class="multiselect-tag-remove-icon"></span>
                                  </span>
                                </div>
                              </template>
                            </Multiselect>
                          </div>
                        </div>

                        <!-- <div v-if="selectedStores.length > 0">
                          <div>
                            <h6>Branch Included</h6> <br />
                          </div>
                          <div class="row">
                            <div v-for="(selectedStore, strindx) in selectedStores" :key="selectedStore.id"
                              class="col-md-4">
                              {{ strindx + 1 }} . <span>{{ selectedStore.branch_name }}</span>
                            </div>
                          </div>
                        </div> -->

                        <!-- <div v-if="selectedStores.length > 0" class="mt-2">
                          <label>Branch Included</label> <br>
                          <ol v-for="(selectedStore) in selectedStores" :key="selectedStore.id" class="selected-cat">
                            <li><span>{{ selectedStore.branch_name }}</span></li>
                          </ol>
                        </div> -->
                      </div>

                      <div class="pl-4 pr-5 bd-b">
                        <h2 class="section-title">Manage Offers</h2>
                        <div class="form-group">
                          <label>Allow Generated Offers Categories </label>
                          <div>
                            <label class="radio-inline"><input type="radio" v-model="allowOfferCategories" :value="true" checked>&nbsp; Yes </label> &nbsp;&nbsp;
                            <label class="radio-inline"><input type="radio" v-model="allowOfferCategories" :value="false"> &nbsp; No</label>

                          </div>
                        </div>
                      </div>

                      <div class="pl-4 pr-5 bd-b">
                        <h2 class="section-title">Pesapal Pos Settings</h2>
                        <div class="form-group">
                          <label>Revenue center (Outlet)</label>
                          <div>
                            <input type="text" class="form-control" v-model="tenderId">
                          </div>
                        </div>
                      </div>

                    </div>


                  </div>
                  <div class="row bt-b">
                    <div class="col-md-12">
                      <div class="form-group ml-2r mt-3">
                        <button type="submit" class="btn btn-orange mr-3">
                          <span v-if="spinner" v-html="searchspinner"></span> <span v-else>Update </span>
                        </button>
                        <router-link :to="{ name: 'activity_log', params: { id: menuId }, query: { logID: 1 } }">
                          <button class="btn btn-primary">View Logs</button>
                        </router-link>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div id="main-loader"></div>
      </div>
    </div>
  </div>
</template>

<script>
import ReusableDataMixins from '@/mixins/ReusableDataMixins';
import ReusableFunctionMixins from '@/mixins/ReusableFunctionMixins';
import Multiselect from '@vueform/multiselect';
import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import { useToast } from "vue-toastification";
const toast = useToast();

export default {
  props: ['token'],
  components: {
    Multiselect
  },
  setup() {
    return { v$: useVuelidate() }
  },
  data() {
    return {
      menuLoading: false,
      spinner: 0,
      searchspinner: "<i class='fa fa-spinner fa-spin '></i> Updating...",
      title: '',
      options: [],
      stores: [],
      selectedStores: [],
      menuId: this.$route.params.menuid,
      startTime: [],
      endTime: [],
      day: [],
      periodTimeCount: [],
      coptions: [],
      thirdparties: [],
      allowOfferCategories:true,
      dayOfWeek: [{ "key": "monday", "value": "Monday" }, { "key": "tuesday", "value": "Tuesday" }, { "key": "wednesday", "value": "Wednesday" }, { "key": "thursday", "value": "Thursday" }, { "key": "friday", "value": "Friday" }, { "key": "saturday", "value": "Saturday" }, { "key": "sunday", "value": "Sunday" }],
      tenderId:''
    }
  },
  validations() {
    return {
      title: { required },
      endTime: { required },
      startTime: { required },
      day: { required }
    }
  },
  mounted() {
    this.getMenuById();
    this.getBranches();
    this.getThirdParties();
    // console.log('root api',process.env)
  },
  computed: {
    startTimes() {
      const startTimeList = []
      for (let i = 1; i <= 23; i++) {
        const time1 = this.convertTime(i)
        let ampmarrival = ''
        if (i >= 12) {
          ampmarrival = 'PM'
        } else {
          ampmarrival = 'AM'
        }
        const constime241 = i.toString() + ':00'
        const constime = time1.toString() + ':00 ' + ampmarrival
        startTimeList.push({ 'value': constime241, 'key': constime })
        const constime1 = time1.toString() + ':30 ' + ampmarrival
        const constime242 = i.toString() + ':30'
        startTimeList.push({ 'value': constime242, 'key': constime1 })
      }
      return startTimeList
    },
    endTimes() {
      const endTimeList = [];
      for (let j = 24; j > 0; j--) {
        const time1 = this.convertTime(j);
        let ampmarrival = '';
        if (j >= 12) {
          ampmarrival = 'PM';
        } else {
          ampmarrival = 'AM';
        }
        const constime241 = j.toString() + ':30';
        const constime1 = time1.toString() + ':30 ' + ampmarrival;
        endTimeList.push({ 'value': constime241, 'key': constime1 });
        const constime242 = j.toString() + ':00'
        const constime = time1.toString() + ':00 ' + ampmarrival
        endTimeList.push({ 'value': constime242, 'key': constime })
      }
      return endTimeList
    }

  },
  methods: {
    async updateMenu() {
      this.spinner = 1;
      const isFormCorrect = await this.v$.$validate()
      if (!isFormCorrect) {
        this.spinner = 0;
        return
      } else {
        const AvailabilityHours = []
        for (let x = 0; x < this.day.length; x++) {
          AvailabilityHours.push({
            "day": this.day[x],
            "start_time": this.startTime[x],
            "end_time": this.endTime[x]
          })
        }
        const selectedStores = [];
        for(let s =0 ; s < this.stores.length ; s++){
          if(!selectedStores.includes(this.stores[s].value)){
            selectedStores.push(this.stores[s].value)
          }
          
        }
        const postData = {
          "id": this.$route.params.menuid,
          'title': this.title,
          "merchant_id": parseInt(this.merchantID),
          'availability_hours': AvailabilityHours,
          "branches": selectedStores,
          "user_id": this.user.id,
          "contains_generated_offer_categories": this.allowOfferCategories,
          "pos_outlet":this.tenderId
          // "merchant_id": 1,
          // "channels": this.thirdparties
        }
        const config = {
          headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
        };
        const jsonParseData = JSON.stringify(postData)
        const url = this.baseUrl + "/menu";
        this.axios.put(url, jsonParseData, config).then((response) => {
          if (response.status == 200) {
            toast.success('menu updated successfully')
            this.spinner = 0;
            this.$router.push({
              name: 'menu'
            })
          }
        }).catch((error) => {
          this.spinner = 0;
          if (error.response.status === 401) {
            this.$router.push({
              name: 'login'
            });
          }
        })
        return;
      }
    },
    async getThirdParties() {
      const config = {
        headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
      };
      const url = this.baseUrl + "/third-party-setting?merchant_id=" + this.merchantID;
      this.axios.get(url, config).then((response) => {
        if (response.status === 200) {
          const channels = response.data.data
          for (let c = 0; c < channels.length; c++) {
            this.coptions.push({
              "value": channels[c].id,
              "name": channels[c].channel_name
            })
          }
        }
      });
    },
    createPeriodCountDivs() {
      this.periodTimeCount.push({
        'key': Math.random()
      });
      return this.periodTimeCount;
    },
    removePeriodCountDivs(ukey, day) {
      this.periodTimeCount = this.periodTimeCount.filter(
        (x) => x.key !== ukey
      );
      this.day = this.day.filter(
        (x) => x !== day
      )
      return this.periodTimeCount;
    },
    getMenuById() {
      this.menuLoading = true;
      const loader = document.getElementById('main-loader');
      loader.style.display = 'block';
      const menuId = this.$route.params.menuid
      const url = this.baseUrl + "/menu/" + menuId + "?merchant_id=" + this.merchantID;
      const config = {
        headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
      };
      this.axios.get(url, config).then((response) => {
        if (response.status === 200) {

          this.title = response.data.title
          this.allowOfferCategories = response.data.contains_generated_offer_categories
          this.tenderId = response.data.pos_outlet
          if (response.data.branches) {
            for (let s = 0; s < response.data.branches.length; s++) {
              this.stores.push({
                "value": response.data.branches[s].id,
                "name": response.data.branches[s].branch_name
              })
            }
            this.selectedStores = response.data.branches;
          }
          // create loop of period div automaticaty
          for (let x = 0; x < response.data.availability_hours.length; x++) {
            this.day[x] = response.data.availability_hours[x].day;
            this.startTime[x] = response.data.availability_hours[x].start_time;
            this.endTime[x] = response.data.availability_hours[x].end_time;
            this.periodTimeCount.push({
              'key': Math.random()
            });
          }

        }
      }).catch((error) => {
        if (error.response.status === 401) {
          this.$router.push({
            name: 'login'
          });
        }

      }).finally(() => {
        this.menuLoading = false;
        loader.style.display = 'none';
      });
    },
    getBranches() {
      const config = {
        headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
      };
      const url = this.baseUrl + "//branch?merchant_id=" + this.merchantID;
      this.axios.get(url, config).then((response) => {
        if (response.status === 200) {
          if (response.data.data !== null) {
            const branches = response.data.data;
            this.options.push({
              "value": [],
              "name": "All"
            })
            for (let i = 0; i < branches.length; i++) {
              this.options.push({
                "value": branches[i].id,
                "name": branches[i].branch_name
              })
            }
          }
        }
      }).catch((error) => {
        if (error.response.status === 401) {
          this.$router.push({
            name: 'login'
          });
        }
      })

    }

  },
  mixins: [ReusableFunctionMixins, ReusableDataMixins]
}
</script>
